$primary-color: #00b9fc;
$border-color: #e5e7eb;
$calendar-border: #e5e7eb;
$cell-border: #e5e7eb;
$event-bg: $primary-color;
$event-border: none;
$event-outline: none;
$current-time-color: #74ad31;
$today-highlight-bg: #eaf6ff;
$link-color: #5091cd;
@import "react-big-calendar/lib/sass/styles";
.rbc-time-view {
  border: none;
  .rbc-time-header {
    background-color: #fff;
    .rbc-time-header-content {
      .rbc-time-header-cell {
        min-height: 50px;
        .rbc-header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .rbc-button-link {
            font-weight: bold;
          }
        }
      }
    }
    .rbc-time-header-gutter {
      border: none;
    }
  }
  .rbc-time-content {
    background-color: #fff;
    .rbc-time-gutter {
      .rbc-timeslot-group {
        border: none;
        min-height: 60px;
        .rbc-time-slot:first-child {
          min-height: 58px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .rbc-label {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
    .rbc-day-slot {
      .rbc-timeslot-group {
        min-height: 60px;
      }
      .rbc-events-container {
        .rbc-event-label,
        .rbc-event-content {
          font-weight: 500;
        }
      }
    }
  }
}
.rbc-month-view {
  border: none;
  .rbc-month-header {
    background-color: #fff;
    min-height: 50px;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    .rbc-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top: 1px solid $border-color;
      font-size: 16px;
    }
  }
  .rbc-month-row {
    .rbc-row-content {
      .rbc-row {
        .rbc-date-cell {
          padding: 12px 16px;
          .rbc-button-link {
            font-size: 16px;
            font-weight: 600;
          }
          .rbc-button-link:hover {
            color: $primary-color;
          }
        }
        .rbc-event-content {
          font-weight: 600;
        }
      }
    }
    .rbc-today {
      background-color: #eaf6ff !important;
    }

    .rbc-off-range {
      visibility: hidden;
    }
    .rbc-off-range-bg {
      background: transparent;
    }
    .rbc-day-bg {
      border-left: 1px solid transparent;

      &:not(.rbc-off-range-bg) {
        background-color: #fff;
        border-left: 1px solid $cell-border;
        &:last-child {
          border-right: 1px solid $cell-border;
        }
      }
    }
    &:last-child {
      .rbc-day-bg {
        &:not(.rbc-off-range-bg) {
          border-bottom: 1px solid $cell-border;
          border-right: 1px solid $cell-border;
          border-left: none;
        }
        &:first-child {
          border-left: 1px solid $cell-border;
        }
      }
    }
  }
}
.link {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}
